import React, { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, MoneyInput } from '@refera/ui-web'
import { Grid, Typography, TextField, Checkbox, FormControlLabel } from '@material-ui/core'

import { Select } from '_/components/inputs'
import AccordionInfo from '_/components/accordion/accordion-info'
import Accordion from '_/views/finance/components/Accordion'
import * as Input from '_/components/inputs/Input'

import useStyles from './styles'
import { complexityOptions } from './constants'

export function ServiceForm({
  service,
  handleCreate = () => {},
  setModalOpen,
  handleDeleteProblem,
  serviceSelectId,
}) {
  const styles = useStyles()
  const formRef = useRef()

  const { control, handleSubmit, setValue, watch, register, errors } = useFormContext()

  const problemsWatch = watch('problems')

  return (
    <form className={styles.categoryForm} ref={formRef} onSubmit={handleSubmit(handleCreate)}>
      <Typography className={styles.title}>
        {serviceSelectId ? 'Editar Serviço' : 'Criar Serviço'}
      </Typography>

      <Grid className={styles.sectionRowWrapper}>
        <Input.Root name="name">
          <Input.Label required name="name" labelClasses={styles.label}>
            Serviço
          </Input.Label>
          <Input.ControllerText
            required
            rules={{
              maxLength: { value: 255, message: `Esse campo aceita no máximo 255 caracteres` },
              required: { value: true, message: 'Esse campo é obrigatório' },
            }}
            name="name"
          />
          <Input.ErrorMessage name="name" />
        </Input.Root>

        <Input.Root name="gridDesc">
          <Input.Label name="gridDesc" labelClasses={styles.label}>
            Descrição da grid
          </Input.Label>
          <Input.ControllerText
            rules={{
              maxLength: { value: 255, message: `Esse campo aceita no máximo 255 caracteres` },
            }}
            name="gridDesc"
          />
          <Input.ErrorMessage name="gridDesc" />
        </Input.Root>
      </Grid>

      <Grid className={styles.sectionWrapper}>
        <Grid style={{ width: '100%' }}>
          <Typography className={styles.label}>Descrição diagnóstico</Typography>
          <TextField
            id="diagnosisDesc"
            multiline
            maxRows={5}
            name="diagnosisDesc"
            inputRef={register}
            defaultValue={service?.diagnosisDesc}
            className={styles.textfield}
            variant="standard"
            InputProps={{
              style: {
                fontSize: 16,
              },
            }}
          />
        </Grid>
        <Grid style={{ width: '100%' }}>
          <Typography className={styles.label}>Descrição Solução</Typography>
          <TextField
            id="solutionDesc"
            multiline
            maxRows={5}
            name="solutionDesc"
            inputRef={register}
            className={styles.textfield}
            variant="standard"
            InputProps={{
              style: {
                fontSize: 16,
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid className={styles.sectionRowWrapper}>
        <MoneyInput
          ref={register('idealLabor')}
          name="idealLabor"
          label="Mão de obra ideal"
          decimalScale={2}
          value={service?.idealLabor?.replace('.', ',') || ''}
          onChange={e => setValue('idealLabor', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
        <MoneyInput
          ref={register('idealMaterial')}
          name="idealMaterial"
          label="Material ideal"
          decimalScale={2}
          value={service?.idealMaterial?.replace('.', ',') || ''}
          onChange={e => setValue('idealMaterial', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
        <MoneyInput
          ref={register('idealPrice')}
          name="idealPrice"
          label="Preço ideal"
          decimalScale={2}
          value={service?.idealPrice?.replace('.', ',') || ''}
          onChange={e => setValue('idealPrice', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
      </Grid>

      <Grid className={styles.sectionWrapper}>
        <MoneyInput
          ref={register('additionalLabor')}
          name="additionalLabor"
          label="Mão de obra adicional"
          decimalScale={2}
          value={service?.additionalLabor?.replace('.', ',') || ''}
          onChange={e => setValue('additionalLabor', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
        <Grid className={styles.textfield}>
          <></>
        </Grid>
        <MoneyInput
          ref={register('additionalPrice')}
          name="additionalPrice"
          label="Preço adicional"
          decimalScale={2}
          value={service?.additionalPrice?.replace('.', ',') || ''}
          onChange={e => setValue('additionalPrice', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
      </Grid>

      <Grid className={styles.sectionWrapper}>
        <MoneyInput
          ref={register('maxLabor')}
          name="maxLabor"
          label="Mão de obra máximo"
          decimalScale={2}
          value={service?.maxLabor?.replace('.', ',') || ''}
          onChange={e => setValue('maxLabor', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
        <MoneyInput
          ref={register('maxMaterial')}
          name="maxMaterial"
          label="Material máximo"
          decimalScale={2}
          value={service?.maxMaterial?.replace('.', ',') || ''}
          onChange={e => setValue('maxMaterial', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
        <MoneyInput
          ref={register('maxPrice')}
          name="maxPrice"
          label="Preço máximo"
          decimalScale={2}
          value={service?.maxPrice?.replace('.', ',') || ''}
          onChange={e => setValue('maxPrice', e.floatValue, { shouldDirty: true })}
          placeholder="R$ "
          className={styles.textfield}
          style={{ fontSize: '14px' }}
        />
      </Grid>

      <Grid className={styles.sectionRowWrapper}>
        <Select
          label="Complexidade"
          name="complexity"
          labelClasses={styles.labelSelect}
          options={complexityOptions}
          defaultValue={service?.complexity}
          rules={{ required: { value: true, message: 'Esse campo é obrigatório' } }}
          error={!!errors?.complexity}
          required
          getValue={item => item.value}
          getLabel={item => item.label}
          getKey={item => item.value}
          menuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
          style={{ gridArea: 'businessFront', width: 'auto' }}
        />

        <Controller
          name="active"
          control={control}
          defaultValue={service?.active}
          render={({ value, onChange }) => {
            return (
              <FormControlLabel
                label="Ativo"
                control={
                  <Checkbox
                    checked={value}
                    defaultChecked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
              />
            )
          }}
        />
        <Controller
          name="techVisit"
          control={control}
          defaultValue={service?.techVisit}
          render={({ value, onChange }) => {
            return (
              <FormControlLabel
                label="Visita técnica"
                control={
                  <Checkbox
                    checked={value}
                    defaultChecked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
              />
            )
          }}
        />
      </Grid>

      <Accordion id="problem-services" title="Problemas vinculados" className={styles.accordion}>
        <Grid className={styles.containerAdd}>
          {problemsWatch?.map((item, index) => (
            <AccordionInfo
              isFirst={index === 0}
              key={item?.id}
              values={item}
              type="service"
              isEditing
              handleDeleteValue={handleDeleteProblem}
            />
          ))}
          <Button
            style={{ marginTop: '20px', maxWidth: '64px' }}
            variant="contained"
            color="primary"
            onClick={() => setModalOpen(true)}
          >
            +Adicionar registro
          </Button>
        </Grid>
      </Accordion>

      <Button
        style={{ width: '120px', marginTop: '15px' }}
        color="primary"
        variant="primary"
        onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))}
      >
        Salvar
      </Button>
    </form>
  )
}
